export default function ({route, app, redirect}) {
    for (let index = 0; index < route.meta.length; index++) {
        let mustPossessAbility = route.meta[index].mustPossessAbility
        if(mustPossessAbility){
            if(!app.$abilitySingelton.can(mustPossessAbility.action, mustPossessAbility.subject)){
                console.log('test');
                redirect({name:'index'})
                //TODO Notify User about missing Permissions
            }
        }
    }
}